import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { MaintenanceReport } from '../../models';

export const featureAdapter = createEntityAdapter<MaintenanceReport>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<MaintenanceReport> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  isLoading: false,
  error: null,
});
