import { createAction, props } from '@ngrx/store';

import {
  LoadMaintenanceReportsRequest,
  MaintenanceReport,
  SaveMaintenanceReportRequest,
} from '../../models';

export const loadRequest = createAction(
  '[Maintenance Reports] Load Request',
  props<{
    request: LoadMaintenanceReportsRequest;
  }>(),
);
export const loadSuccess = createAction(
  '[Maintenance Reports] Load Success',
  props<{
    reports: MaintenanceReport[];
  }>(),
);
export const loadFailure = createAction(
  '[Maintenance Reports] Load Failure',
  props<{
    error: any;
  }>(),
);

export const createRequest = createAction(
  '[Maintenance Reports] Create Request',
  props<{
    request: SaveMaintenanceReportRequest;
    onSuccess?: () => void;
  }>(),
);
export const createSuccess = createAction(
  '[Maintenance Reports] Create Success',
  props<{
    report: MaintenanceReport;
  }>(),
);
export const createFailure = createAction(
  '[Maintenance Reports] Create Failure',
  props<{
    error: any;
  }>(),
);

export const updateRequest = createAction(
  '[Maintenance Reports] Update Request',
  props<{
    request: SaveMaintenanceReportRequest;
    onSuccess?: () => void;
  }>(),
);
export const updateSuccess = createAction(
  '[Maintenance Reports] Update Success',
  props<{
    report: MaintenanceReport;
  }>(),
);
export const updateFailure = createAction(
  '[Maintenance Reports] Update Failure',
  props<{
    error: any;
  }>(),
);

export const deleteRequest = createAction(
  '[Maintenance Reports] Delete Request',
  props<{
    request: { property_id: number; report_id: number };
  }>(),
);
export const deleteSuccess = createAction(
  '[Maintenance Reports] Delete Success',
  props<{
    report_id: number;
  }>(),
);
export const deleteFailure = createAction(
  '[Maintenance Reports] Delete Failure',
  props<{
    error: any;
  }>(),
);

export const resetState = createAction('[Maintenance Reports] Reset State');
